//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { API } from 'aws-amplify'
import msDynamics from '~/mixins/msDynamics'
import { saveSubmissionLog } from '~/utils/submissionLogs'
import { getFormById, resolveCountryValue } from '~/utils/msDynamics'
import getFormMentorsVariations from '~/gql/getFormMentorsVariations.js'

export default {
  mixins: [msDynamics],
  props: {
    theme: {
      type: String,
      default: null,
    },
    focus: {
      type: Object,
      default: null,
    },
    alternative: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      userBranch: process.env.USER_BRANCH,
      loading: false,
      submitted: false,
      error: false,
      currentStep: 0,
      steps: [
        'FormContactMentorEnGbStepWho',
        'FormContactMentorEnGbStepLimitation',
        'FormContactMentorEnGbStepQuestion',
        'FormContactMentorEnGbStepContact',
      ],
      formData: {},
      questionListData: {},
      questionList: [],
      questionsAnswers: [],
    }
  },
  async fetch() {
    try {
      this.questionList = []
      this.questionsAnswers = []
      this.questionListData = await this.$graphcms.request(
        getFormMentorsVariations,
        {
          // the hygraph id for this form variation
          id: 'clmp0u4n10pnw0aul3quzc3tv',
        }
      )

      if (isObjectNotEmpty(this.questionListData)) {
        const questionList =
          this.questionListData.mentorsFormVariation.questionList

        questionList.forEach((question) => {
          const localization = question.localizations.find(
            (loc) => loc.locale === this.$i18n.locale
          )
          if (localization) {
            this.questionList.push(localization.questionTitle)
            this.questionsAnswers.push(localization.questionsAnswers)
          }
        })
      }
    } catch (error) {
      console.error('Error fetching question data', error)
    }
  },

  computed: {
    classList() {
      return {
        ['form--theme-' + this.theme]: this.theme,
        'alternative-form': this.alternative,
      }
    },
    focusText() {
      if (!this.focus || !this.focus.type) {
        return null
      }
      switch (this.focus.type) {
        case 'mentor':
          return `Mentor: ${this.focus.firstName} ${this.focus.lastName}`
        case 'country':
          return `Country: ${this.focus.country}`
      }
      return null
    },
  },

  methods: {
    buildMsd() {
      return {
        main: getFormById('main'),
        newsletter: getFormById('newsletter'),
      }
    },

    onInput(value) {
      this.formData = {
        ...this.formData,
        ...value,
      }
    },

    prepareEmailSteps() {
      return {
        step1: {
          question: this.questionList[0],
          answer: this.formData.who,
        },
        step2: {
          question: this.questionList[1],
          answer: this.formData.tellmore,
        },
        step3: {
          question: this.questionList[2],
          answer: this.formData.question,
        },
      }
    },

    async onSubmit() {
      this.error = false
      this.loading = true
      this.submitted = false

      if (this.userBranch === '') {
        console.log('matomo disabled for local environments')
      } else {
        // eslint-disable-next-line no-undef
        const currentPageURL = window.location.href
        // eslint-disable-next-line no-undef
        window._mtm = window._mtm || []
        // eslint-disable-next-line no-undef
        window._mtm.push({
          event: 'ctm-form-step4-start',
          'event-category': 'ctm-form-submission',
          'event-action': `CLICK_LINK_URL - ${currentPageURL}`,
        })

        // Custom Step Matomo Tracking
        // eslint-disable-next-line no-undef
        _paq.push(['setCustomUrl', 'ContactMentorForm Step 4 - Submit'])

        // Track the page view in Matomo.
        // eslint-disable-next-line no-undef
        _paq.push(['trackPageView', 'ContactMentorForm Step 4 - Submit'])
        // eslint-disable-next-line no-undef
        _paq.push([
          'trackEvent',
          'Mentors Form',
          'Step Form Changed',
          'Step 4 - Submit',
        ])
      }

      try {
        // sync values to msd forms form
        await this.syncMainForm()
        await this.syncNewsletterForm()

        // submit msd forms
        const success = await this.submitMsd()
        if (!success) {
          this.error = true
        }

        if (this.userBranch !== '') {
          // eslint-disable-next-line no-undef
          const currentPageURL = window.location.href
          // eslint-disable-next-line no-undef
          window._mtm = window._mtm || []
          // eslint-disable-next-line no-undef
          window._mtm.push({
            event: 'ctm-form-submission-complete',
            'event-category': 'ctm-form-submission',
            'event-action': `CLICK_LINK_URL - ${currentPageURL}`,
            email: this.formData.email,
            country: this.formData.country,
          })
        }
      } catch (e) {
        console.error(e)
        this.error = true
      }

      this.submitToMailer().catch(console.error)

      this.loading = false
      this.submitted = true
    },

    async submitToMailer() {
      let mentorData = null
      if (this.focus && this.focus.type === 'mentor') {
        mentorData = {
          id: this.focus.id,
          firstName: this.focus.firstName,
          lastName: this.focus.lastName,
        }

        if (this.userBranch !== '') {
          // eslint-disable-next-line no-undef
          const currentPageURL = window.location.href
          // eslint-disable-next-line no-undef
          window._mtm = window._mtm || []
          // eslint-disable-next-line no-undef
          window._mtm.push({
            event: 'community-sign-up-complete',
            'event-category': 'community-sign-up-complete',
            'event-action': `CLICK_LINK_URL - ${currentPageURL}`,
            email: this.formData.email,
            country: this.formData.country,
          })
        }
      }

      try {
        const steps = this.prepareEmailSteps()

        const response = await API.post('ses', '/mail', {
          body: {
            ...this.formData,
            country: resolveCountryValue(this.formData.country, this.$store),
            countryCode: this.formData.country,
            mentor: mentorData,
            ...steps,
          },
        })
        await saveSubmissionLog({
          type: 'success ses mail',
          responsePost: response,
          body: {
            ...this.formData,
            country: resolveCountryValue(this.formData.country, this.$store),
            countryCode: this.formData.country,
            mentor: mentorData,
            ...steps,
          },
        })
      } catch (er) {
        await saveSubmissionLog({ type: 'error ses mail', errorLog: er })
      }
    },

    async submitMsd() {
      const results = await Promise.all([
        this.msDynamics.main.actions.submit(),
        this.msDynamics.newsletter.actions.submit(),
      ])
      try {
        await saveSubmissionLog({
          type: 'results msdynamics',
          resultsAll: results,
          form: { ...this.formData },
        })
      } catch {}
      return results[0]
    },

    onStep(step) {
      this.currentStep = parseInt(step)
    },

    onPrev() {
      // eslint-disable-next-line no-var
      // var _paq = (window._mtm = window._mtm || [])
      this.currentStep = this.currentStep - 1

      if (this.userBranch === '') {
        console.log('matomo disabled for local environments')
      } else {
        if (this.currentStep === 0) {
          // eslint-disable-next-line no-undef
          const currentPageURL = window.location.href
          // eslint-disable-next-line no-undef
          window._mtm = window._mtm || []
          // eslint-disable-next-line no-undef
          window._mtm.push({
            event: 'ctm-form-submission-start',
            'event-category': 'ctm-form-submission',
            'event-action': `CLICK_LINK_URL - ${currentPageURL}`,
          })
        }

        if (this.currentStep === 1) {
          // eslint-disable-next-line no-undef
          const currentPageURL = window.location.href
          // eslint-disable-next-line no-undef
          window._mtm = window._mtm || []
          // eslint-disable-next-line no-undef
          window._mtm.push({
            event: 'ctm-form-step2-start',
            'event-category': 'ctm-form-submission',
            'event-action': `CLICK_LINK_URL - ${currentPageURL}`,
          })
        }

        if (this.currentStep === 2) {
          // eslint-disable-next-line no-undef
          const currentPageURL = window.location.href
          // eslint-disable-next-line no-undef
          window._mtm = window._mtm || []
          // eslint-disable-next-line no-undef
          window._mtm.push({
            event: 'ctm-form-step3-start',
            'event-category': 'ctm-form-submission',
            'event-action': `CLICK_LINK_URL - ${currentPageURL}`,
          })
        }

        // eslint-disable-next-line no-undef
        _paq.push([
          'setCustomUrl',
          'ContactMentorForm Step ' + this.currentStep,
        ])

        // Track the page view in Matomo
        // eslint-disable-next-line no-undef
        _paq.push([
          'trackPageView',
          'ContactMentorForm Step ' + this.currentStep,
        ])
        // eslint-disable-next-line no-undef
        _paq.push([
          'trackEvent',
          'Mentors Form',
          'Step Form Changed',
          'Step ' + this.currentStep,
        ])
      }
    },

    onNext() {
      // eslint-disable-next-line no-var
      // var _paq = (window._mtm = window._mtm || [])
      this.currentStep = this.currentStep + 1

      if (this.userBranch === '') {
        console.log('matomo disabled for local environments')
      } else {
        if (this.currentStep === 1) {
          // eslint-disable-next-line no-undef
          const currentPageURL = window.location.href
          // eslint-disable-next-line no-undef
          window._mtm = window._mtm || []
          // eslint-disable-next-line no-undef
          window._mtm.push({
            event: 'ctm-form-submission-start',
            'event-category': 'ctm-form-submission',
            'event-action': `CLICK_LINK_URL - ${currentPageURL}`,
          })
        }

        if (this.currentStep === 2) {
          // eslint-disable-next-line no-undef
          const currentPageURL = window.location.href
          // eslint-disable-next-line no-undef
          window._mtm = window._mtm || []
          // eslint-disable-next-line no-undef
          window._mtm.push({
            event: 'ctm-form-step2-start',
            'event-category': 'ctm-form-submission',
            'event-action': `CLICK_LINK_URL - ${currentPageURL}`,
          })
        }

        if (this.currentStep === 3) {
          // eslint-disable-next-line no-undef
          const currentPageURL = window.location.href
          // eslint-disable-next-line no-undef
          window._mtm = window._mtm || []
          // eslint-disable-next-line no-undef
          window._mtm.push({
            event: 'ctm-form-step3-start',
            'event-category': 'ctm-form-submission',
            'event-action': `CLICK_LINK_URL - ${currentPageURL}`,
          })
        }

        // eslint-disable-next-line no-undef
        _paq.push([
          'setCustomUrl',
          'ContactMentorForm Step ' + this.currentStep,
        ])

        // Track the page view in Matomo
        // eslint-disable-next-line no-undef
        _paq.push([
          'trackPageView',
          'ContactMentorForm Step ' + this.currentStep,
        ])
        // eslint-disable-next-line no-undef
        _paq.push([
          'trackEvent',
          'Mentors Form',
          'Step Form Changed',
          'Step ' + this.currentStep,
        ])
      }
    },

    async syncMainForm() {
      const fields = this.msDynamics.main.actions
      fields.firstName(this.formData.firstname || '')
      fields.lastName(this.formData.lastname || '')
      fields.email(this.formData.email || '')
      fields.contactConsent(this.formData.newsletter || false)
      await fields.country(
        resolveCountryValue(this.formData.country, this.$store)
      )
      fields.gender('unknown')
      fields.website(window.location.href)
      const textContent = []
      if (this.focusText) {
        textContent.push(['Submission Recipient', this.focusText])
      }
      textContent.push([
        'I would like to talk to someone about:',
        this.formData.who || '-',
      ])
      textContent.push([
        'Where are you, or you loved one on their hearing journey?',
        this.formData.tellmore || '-',
      ])
      textContent.push([
        'Tell us more about your/your loved ones hearing situation',
        this.formData.question || '-',
      ])

      if (this.$i18n.locale === 'en_gb') {
        textContent.push(['Phone number', this.formData.phone || '-'])
        textContent.push(['Clinic', this.formData.clinic || '-'])
      }

      fields.textField(textContent.map((v) => v.join('\n')).join('\n\n'))
    },
    async syncNewsletterForm() {
      const fields = this.msDynamics.newsletter.actions
      fields.mentoringProgram(true)
      fields.firstName(this.formData.firstname || '')
      fields.lastName(this.formData.lastname || '')
      fields.email(this.formData.email || '')
      await fields.country(
        resolveCountryValue(this.formData.country, this.$store)
      )
      fields.language(
        this.$store.getters['locale/getDefaultNameByLanguageCode'](
          this.$i18n.locale || 'english'
        )
      )
    },
  },
}
// Utility function to check if an object is not empty
function isObjectNotEmpty(obj) {
  return Object.keys(obj).length > 0
}
